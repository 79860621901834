import React from "react"
import Layout from "components/Layout/LayoutNewYork"
import PageHeader from "components/shared/PageHeader"
import styled from "styled-components";
import {Container} from 'reactstrap';

const Content = styled(Container)`
    position: relative;
    margin: 0 auto;
    text-align: left;
`

const CookiePage = () => (
    <Layout
        title="Cookies Policy | New York | BTTF"
        booking="https://www.telecharge.com/Broadway/Back-to-the-Future-the-Musical/Overview?AID=BWY001390400&utm_source=show_site&utm_campaign=Back-to-the-Future-the-MusicalSS&utm_medium=web"
    >
        <PageHeader city="new-york" title="Cookies Policy" />
        <Content className="my-3 my-md-5">
            <p>
                <strong></strong>
            </p>
            <p>
                <strong>
                    <br/>
                    Cookies and how they benefit you:
                </strong>
            </p>
            <p>
                <br/>
                Our website uses cookies, as almost all websites do, to help provide you
                with the best experience we can. Cookies are small text files that are
                placed on your computer or mobile phone when you browse websites.
                <br/>
                <br/>
            </p>
            <p>
            To read our Privacy Policy please click    <a href="http://backtothefuturemusical.com/privacy-policy">here</a>.
            </p>
            <p>
                <br/>
                Back To the Future The Musical uses cookies for three main purposes:
            </p>
            <p>
                &bull; To ensure the website works, in particular for online booking
            </p>
            <p>
                &bull; To monitor website performance and help us make improvements in the
                future
            </p>
            <p>
                &bull; To tailor our marketing and use tools such as Google AdWords to
                communicate more effectively through web advertising.
            </p>
            <p>
                <strong>
                    <br/>
                    Our cookies help us:
                    <br/>
                    <br/>
                </strong>
            </p>
            <p>
                &bull; Make our website work as you’d expect
            </p>
            <p>
                &bull; Save you having to login every time you visit the site
            </p>
            <p>
                &bull; Remember your settings during and between visits
            </p>
            <p>
                &bull; Improve the speed/security of the site
            </p>
            <p>
                &bull; Allow you to share pages with social networks like Facebook
            </p>
            <p>
                &bull; Continuously improve our website for you
            </p>
            <p>
                &bull; Make our marketing more efficient (ultimately helping us to offer the
                service we do at the price we do)
                <br/>
                <br/>
            </p>
            <p>
                <strong>
                    We do not use cookies to:
                    <br/>
                    <br/>
                </strong>
            </p>
            <p>
                &bull; Collect any sensitive information
            </p>
            <p>
                &bull; Pass personally identifiable data to third parties
            </p>
            <p>
                &bull; Pay sales commissions
            </p>
            <p>
                You can learn more about all the cookies we use below:
            </p>
            <table cellspacing="0" cellpadding="0" width="100%">
                <tbody>
                    <tr>
                        <td width="50%">
                            <p>
                                Cookie
                            </p>
                        </td>
                        <td width="50%">
                            <p>
                                Purpose
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="50%">
                            <p>
                                Google Analytics
                            </p>
                        </td>
                        <td width="50%">
                            <p>
                                This monitors how people use our site – for example the
                                browser they use and the pages they visit. It’s used to
                                assess our website’s performance and to help us plan
                                improvements.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="50%">
                            <p>
                                DoubleClick / Sizmek
                            </p>
                        </td>
                        <td width="50%">
                            <p>
                                You may notice that sometimes after visiting our site you
                                see increased numbers of adverts from us. This is because
                                we pay for these adverts, and the technology to do this is
                                made possible by this cookie. We use these adverts to
                                encourage you to come back to our site. Don’t worry - we
                                are unable to proactively reach out to you as the whole
                                process is entirely anonymised.
                            </p>
                            <p>
                                These help us understand how well our online adverts are
                                encouraging browsers to view more information about
                                particular events on
                                <a href="http://backtothefuturemusical.com">
                                    http://backtothefuturemusical.com
                                </a>
                                and when an advert is displayed on a third-party website, a
                                Sizmek / Doubleclick cookie is placed on their machine,
                                providing this is enabled by their browser settings. If
                                that browser then visits
                                <a href="http://backtothefuturemusical.com">
                                    http://backtothefuturemusical.com
                                </a>
                                , we can see that the advert has been effective. More
                                information about Sizmek’s or Doubleclick’s own privacy
                                policy and <a href="https://www.sizmek.com/privacy-policy/optedin/">
                                    how to opt out can be found here on the Sizmek /
                                    Doubleclick website
                                </a>
                                .
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="50%">
                            <p>
                                Facebook / Instagram / Twitter / Snapchat
                            </p>
                        </td>
                        <td width="50%">
                            <p>
                                This communicates with Facebook / Instagram / Snapchat /
                                Twitter activity on our website. In a similar way to
                                DoubleClick / Sizmek it allows us to reduce our costs (and
                                keep our prices low) by using digital adverts to encourage
                                you to visit us. Don’t worry - we are unable to proactively
                                reach out to you as the whole process is entirely
                                anonymised.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width="50%">
                            <p>
                                Session
                            </p>
                        </td>
                        <td width="50%">
                            <p>
                                This cookie is a transient cookie (it is erased when you
                                close the browser). It acts as a small amount of memory to
                                remember what you’ve done on past pages.
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p>
                <strong></strong>
            </p>
            <p>
                <strong>
                    Granting us permission to use cookies
                    <br/>
                    <br/>
                </strong>
            </p>
            <p>
                If the settings on your software that you are using to view this website
                (your browser) are adjusted to accept cookies we take this, and your
                continued use of our website, to mean that you are fine with this. Should
                you wish to remove or not use cookies from our site you can learn how to do
                this below; however doing so will likely mean that our site will not work
                as you would expect.
            </p>
            <p>
                <strong>
                    Our own cookies
                    <br/>
                    <br/>
                </strong>
            </p>
            <p>
                We use cookies to make our website work by remembering your search
                settings.
            </p>
            <p>
                <strong>Third party functions</strong>
            </p>
            <p>
                Our site, like most websites, includes functionality provided by third
                parties. A common example is an embedded YouTube video. Disabling these
                cookies will likely break the functions offered by these third parties.
            </p>
            <p>
                <strong>Social website cookies</strong>
            </p>
            <p>
                So you can easily “like” or share our content on the likes of Facebook and
                Twitter we have included sharing buttons on our site.
            </p>
            <p>
                The privacy implications of this will vary depending on the social network
                and will be dependent on the privacy settings you have chosen on these
                networks. [Delete if not applicable]
            </p>
            <p>
                <strong>Anonymous visitor statistics cookies</strong>
            </p>
            <p>
                We use cookies to compile visitor statistics such as how many people have
                visited our website, what type of technology they are using (e.g. Mac or
                Windows, which helps to identify when our site isn’t working as it should
                for particular technologies), how long they spend on the site, what page
                they look at etc. This helps us to continuously improve our website. These
                so called “analytics” programs also tell us if , on an anonymous basis, how
                people reached this site (e.g. from a search engine) and whether they have
                been here before; this helps us to put more money into developing our
                services for you instead of marketing spend.
            </p>
            <p>
                <strong>We use advertising cookies</strong>
            </p>
            <p>
                Cookies are widely used in online advertising. Neither us, advertisers or
                our advertising partners can gain personally identifiable information from
                these cookies. We only work with advertising partners who work to accepted
                privacy standards such as
                <a href="http://www.youronlinechoices.com/goodpractice">
                    http://www.youronlinechoices.com/goodpractice
                </a>
                .
            </p>
            <p>
                You can learn more about online advertising at
                <a href="http://www.youronlinechoices.com">
                    http://www.youronlinechoices.com
                </a>
                . You can opt-out of almost all advertising cookies at
                <a href="http://www.youronlinechoices.com/uk/your-ad-choices">
                    http://www.youronlinechoices.com/uk/your-ad-choices
                </a>
                although we would prefer that you didn’t, as ultimately advertising helps
                keep much of the internet free. It is also worth noting that opting out of
                advertising cookies will not mean you won’t see adverts, just simply that
                they won’t be tailored to you any longer.
            </p>
            <p>
                <strong>
                    We use:
                    <br/>
                    <br/>
                </strong>
            </p>
            <p>
                &bull; DoubleClick – privacy policy owned by Google; Sizmek – privacy policy
                owned by Sizmek
            </p>
            <p>
                &bull; Remarketing cookies
            </p>
            <p>
                You may notice that sometimes after visiting a site you see increased
                numbers of adverts from the site you visited. This is because advertisers,
                including us, pay for these adverts. The technology to do this is made
                possible by cookies and as such we may place a so called “remarketing
                cookie” during your visit. We use these adverts to offer special offers etc
                to encourage you to come back to our site. Don’t worry, we are unable to
                proactively reach out to you as the whole process is entirely anonymised.
                You can opt out of these cookies at any time as explained above.
            </p>
            <p>
                <strong>Managing cookies</strong>
            </p>
            <p>
                <strong></strong>
            </p>
            <p>
                Cookies cannot be used by themselves to identify you. You can easily
                control and disable cookies through your browser settings.
            </p>
            <p>
                All browsers are different – for more information on cookies in general,
                visit the following external links:
            </p>
            <p>
                <a href="http://www.aboutcookies.org/">www.aboutcookies.org</a>
                <br/>
                <a href="http://www.youronlinechoices.eu/">www.youronlinechoices.eu</a>
            </p>
            <p>
                Depending on the browser you are using, cookies can be managed according to
                the steps shown in the below table.
            </p>
            <p>
                Please be aware that by limiting or deleting cookies, your experience of
                our website might not be the best it could be. If you have any questions
                about cookies, please contact the Data Protection Manager Phoebe
                Fairbrother in the following ways:
            </p>
            <p>
                · By post at this address: McFly Productions Limited, Unit 10, 10-11 Archer
                Street, Soho, London, W1D 7AZ<u></u>
            </p>
            <p>
                · By email:
                <a href="mailto:info@intheatreproductions.com">
                    info@intheatreproductions.com
                </a>
                <u></u>
            </p>
            <p>
                <strong></strong>
            </p>
            <p>
                <strong>Turning cookies off</strong>
            </p>
            <p>
                You can usually switch cookies off by adjusting your browser settings to
                stop it from accepting cookies. Doing so, however, will likely limit the
                functionality of this site - and a large proportion of the world’s websites
                - as cookies are a standard part of most modern websites.
            </p>
            <p>
                Most web browsers allow some control of most cookies through the browser
                settings. To find out more about cookies, including how to see what cookies
            have been set and how to manage and delete them, visit<a href="http://www.aboutcookies.org">www.aboutcookies.org</a><u> </u> or    <a href="http://www.allaboutcookies.org">www.allaboutcookies.org</a>. To
                opt out of being tracked by Google Analytics across all websites visit
                <a href="http://tools.google.com/dlpage/gaoptout">
                    http://tools.google.com/dlpage/gaoptout
                </a>
                .
            </p>
            <p>
                <br/>
                It may be that your concerns around cookies relate to so called “spyware”.
                Rather than switching off cookies in your browser you may find that
                anti-spyware software achieves the same objective by automatically deleting
                cookies considered to be invasive. Learn more about managing cookies with
                anti-spyware software.
            </p>
        </Content>
    </Layout>
)

export default CookiePage
